import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import styles from "./SignIn.module.css";
import { useState } from "react";

export default function SignIn() {
    const [showComponent, setShowComponent] = useState(false);

    return (
        <>
            <div className={styles.main}>
                <h3>Please sign in to continue</h3>
                <GoogleOAuthProvider
                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                >
                    <GoogleLogin
                        onSuccess={(credentialResponse) => {
                            const details = jwt_decode(
                                credentialResponse.credential
                            );
                            if (
                                details.email ==
                                process.env.REACT_APP_AUTHORISED_EMAIL
                            ) {
                                window.localStorage.setItem("isLoggedIn", true);
                                window.location.reload();
                            } else {
                                setShowComponent(true);
                            }
                        }}
                        onError={() => {
                            console.log("Login Failed");
                        }}
                    />
                </GoogleOAuthProvider>
            </div>
            {showComponent ? (
                <div className={styles.noteText}>
                    Please contact an administrator for admin access
                </div>
            ) : null}
        </>
    );
}
