import React from "react";
import styles from "./Blogs.module.css";
import { useState, useEffect } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Buffer = require("buffer/").Buffer;
var Loader = require("react-loader");
const moment = require("moment");

export default function Blogs() {
    const server = process.env.REACT_APP_SERVER;

    const [addBox, setAddBox] = useState(false);
    const [blogsData, setBlogsData] = useState([]);
    const [editArray, setEditArray] = useState([]);
    const [loaded, setLoaded] = useState(true);
    const [todayDate, setTodayDate] = useState();

    const [descEN, setDescEN] = useState("");
    const [descES, setDescES] = useState("");

    let newWidth = 400;

    function compressImage(image) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsDataURL(image);

            reader.onload = (event) => {
                let image_url = event.target.result;

                let image = document.createElement("img");

                image.src = image_url;

                image.onload = (e) => {
                    let canvas = document.createElement("canvas");
                    let aspectRatio = newWidth / e.target.width;
                    canvas.width = newWidth;
                    canvas.height = e.target.height * aspectRatio;

                    const context = canvas.getContext("2d");
                    context.drawImage(image, 0, 0, canvas.width, canvas.height);

                    let newImageUrl = context.canvas.toDataURL(
                        "image/jpeg",
                        100
                    ); // 70% of the original quality

                    resolve(newImageUrl);
                };
            };

            reader.onerror = (error) => {
                reject(error);
            };
        });
    }

    function deleteData(element) {
        setLoaded(false);
        axios
            .post(server + "/deleteBlogsData", element)
            .then((response) => {
                alert("Delete Successful");
                setLoaded(true);
                window.location.reload();
            })
            .catch((error) => {
                alert(
                    "Server error. Please try again or contact administrator"
                );
                console.log(error);
                setLoaded(true);
            });
    }

    function updateData(element) {
        setLoaded(false);

        let newData = {
            en: {
                title: element.en.title,
                description: element.en.description,
            },
            es: {
                title: element.es.title,
                description: element.es.description,
            },
            publishedBy: element.publishedBy,
            date: element.date,
        };

        newData._id = element._id;

        axios
            .post(server + "/updateBlogsData", newData)
            .then((response) => {
                alert("Update Successful");
                setLoaded(true);
                window.location.reload();
            })
            .catch((error) => {
                alert(
                    "Server error. Please try again or contact administrator"
                );
                console.log(error);
                setLoaded(true);
            });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Display the loader
        setLoaded(false);

        const imageInput = document.getElementById("image-input");
        const images = Array.from(imageInput.files);

        const imageBase64Array = await Promise.all(
            images.map((image) => {
                return new Promise((resolve, reject) => {
                    compressImage(image)
                        .then((compressedImage) => {
                            resolve(compressedImage);
                        })
                        .catch(reject);
                });
            })
        );

        console.log(imageBase64Array);

        const formData = new FormData(event.target);
        imageBase64Array.forEach((base64, index) => {
            formData.append(`imagesBase64[${index}]`, base64);
        });

        formData.append("descriptionEN", descEN);
        formData.append("descriptionES", descES);

        // console.log(formData.get("descriptionEN"));
        // console.log(formData.get("descriptionES"));

        // Perform the form submission using Fetch API or any other library (e.g., Axios)
        fetch(server + "/saveBlogsData", {
            method: "POST",
            body: formData,
        })
            .then((response) => {
                alert("New data added");
                setLoaded(true);
                window.location.reload();
            })
            .catch((error) => {
                alert(
                    "Server error. Please try again or contact administrator"
                );
                console.error(error);
                setLoaded(true);
            });
    };

    useEffect(() => {
        setLoaded(false);

        setLoaded(false);
        var currDate = new Date();
        var dd = String(currDate.getDate()).padStart(2, "0");
        var mm = String(currDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = currDate.getFullYear();

        currDate = yyyy + "-" + mm + "-" + dd;

        setTodayDate(currDate);

        axios
            .get(server + "/getBlogsData")
            .then((response) => {
                const data = response.data;
                console.log(data);
                setBlogsData(data);

                let tempEditArray = [];

                for (let i = 0; i < data.length; i++) {
                    tempEditArray.push(false);
                }

                setEditArray(tempEditArray);
                setLoaded(true);
            })
            .catch((error) => {
                alert(
                    "Server error. Please try again or contact administrator"
                );
                console.log(error);
                setLoaded(true);
            });
    }, []);

    return (
        <div>
            <div className={styles.header}>
                {addBox ? (
                    <div
                        className={styles.addButton}
                        onClick={() => {
                            setAddBox(!addBox);
                        }}
                    >
                        Close
                    </div>
                ) : (
                    <div
                        className={styles.addButton}
                        onClick={() => {
                            setAddBox(!addBox);
                        }}
                    >
                        New
                    </div>
                )}
            </div>

            <Loader loaded={loaded}>
                {addBox ? (
                    <form
                        enctype="multipart/form-data"
                        method="POST"
                        onSubmit={handleSubmit}
                    >
                        <div className={styles.addBox}>
                            <div className={styles.addSubBox}>
                                <div>Add image: </div>
                                <input
                                    id="image-input"
                                    accept="image/*"
                                    type="file"
                                    max="20971520"
                                    name="images"
                                    multiple
                                />
                            </div>
                            <div className={styles.addSubBox}>
                                <div>Add Title (EN): </div>
                                <input type="text" name="titleEN" />
                            </div>
                            <div className={styles.addSubBox}>
                                <div>Add Description (EN): </div>
                                {/* <textarea name="descriptionEN" /> */}
                                <ReactQuill
                                    name="descriptionEN"
                                    theme="snow"
                                    value={descEN}
                                    onChange={setDescEN}
                                />
                            </div>
                            <div className={styles.addSubBox}>
                                <div>Add Title (ES): </div>
                                <input type="text" name="titleES" />
                            </div>
                            <div className={styles.addSubBox}>
                                <div>Add Description (ES): </div>
                                {/* <textarea name="descriptionES" /> */}
                                <ReactQuill
                                    name="descriptionES"
                                    theme="snow"
                                    value={descES}
                                    onChange={setDescES}
                                />
                            </div>
                            <div className={styles.addSubBox}>
                                <div>Published By: </div>
                                <input
                                    type="text"
                                    name="publishedBy"
                                    defaultValue="Heritage Quest anveshanam"
                                />
                            </div>
                            <div className={styles.addSubBox}>
                                <div>Date: </div>
                                <input
                                    type="date"
                                    name="date"
                                    defaultValue={todayDate}
                                />
                            </div>
                            <input
                                type="submit"
                                value="Add"
                                className={styles.addButton}
                            />
                        </div>
                    </form>
                ) : null}

                <table>
                    <tr>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Title (ES)</th>
                        <th>Description (ES)</th>
                        <th>Published By</th>
                        <th>Date (yyyy-mm-dd)</th>
                        <th>Images</th>
                        <th>Delete</th>
                        <th>Edit</th>
                    </tr>
                    {blogsData.map((element, index) => {
                        return editArray[index] == false ? (
                            <tr>
                                <td>{element.en.title}</td>
                                {/* <td>{element.en.description}</td> */}
                                <td
                                    dangerouslySetInnerHTML={{
                                        __html: element.en.description,
                                    }}
                                />
                                <td>{element.es.title}</td>
                                {/* <td>{element.es.description}</td> */}
                                <td
                                    dangerouslySetInnerHTML={{
                                        __html: element.es.description,
                                    }}
                                />
                                <td>{element.publishedBy}</td>
                                <td>{element.date}</td>
                                <td className={styles.imageColumn}>
                                    {element.images.map((image) => {
                                        return <img src={image.url} />;
                                    })}
                                </td>
                                <td>
                                    <button
                                        className={styles.deleteBtn}
                                        onClick={() => {
                                            deleteData(element);
                                        }}
                                    >
                                        Delete
                                    </button>
                                </td>
                                <td>
                                    <button
                                        className={styles.editBtn}
                                        onClick={() => {
                                            let tempEditArray = [...editArray];
                                            tempEditArray[index] = true;
                                            setEditArray(tempEditArray);
                                        }}
                                    >
                                        Edit
                                    </button>
                                </td>
                            </tr>
                        ) : (
                            <tr>
                                <td>
                                    <input
                                        placeholder="Title (EN)"
                                        defaultValue={element.en.title}
                                        onChange={(e) => {
                                            let tempBlogsData = blogsData;
                                            tempBlogsData[index].en.title =
                                                e.target.value;
                                            setBlogsData(tempBlogsData);
                                        }}
                                    />
                                </td>
                                <td>
                                    <textarea
                                        placeholder="Description (EN)"
                                        defaultValue={element.en.description}
                                        onChange={(e) => {
                                            let tempBlogsData = blogsData;
                                            tempBlogsData[
                                                index
                                            ].en.description = e.target.value;
                                            setBlogsData(tempBlogsData);
                                        }}
                                    />
                                </td>
                                <td>
                                    <input
                                        placeholder="Title (ES)"
                                        defaultValue={element.es.title}
                                        onChange={(e) => {
                                            let tempBlogsData = blogsData;
                                            tempBlogsData[index].es.title =
                                                e.target.value;
                                            setBlogsData(tempBlogsData);
                                        }}
                                    />
                                </td>
                                <td>
                                    <textarea
                                        placeholder="Description (ES)"
                                        defaultValue={element.es.description}
                                        onChange={(e) => {
                                            let tempBlogsData = blogsData;
                                            tempBlogsData[
                                                index
                                            ].es.description = e.target.value;
                                            setBlogsData(tempBlogsData);
                                        }}
                                    />
                                </td>
                                <td>
                                    <input
                                        placeholder="Published By"
                                        defaultValue={element.publishedBy}
                                        onChange={(e) => {
                                            let tempBlogsData = blogsData;
                                            tempBlogsData[index].publishedBy =
                                                e.target.value;
                                            setBlogsData(tempBlogsData);
                                        }}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="date"
                                        name="date"
                                        defaultValue={element.date}
                                        onChange={(e) => {
                                            let tempBlogsData = blogsData;
                                            tempBlogsData[index].date = moment(
                                                new Date(e.target.value)
                                            ).format("YYYY-MM-DD");
                                            setBlogsData(tempBlogsData);
                                        }}
                                    />
                                </td>
                                <td className={styles.imageColumn}>
                                    {element.images.map((image) => {
                                        return <img src={image.url} />;
                                    })}
                                </td>
                                <td>
                                    <button
                                        className={styles.deleteBtn}
                                        onClick={() => {
                                            deleteData(element);
                                        }}
                                    >
                                        Delete
                                    </button>
                                </td>
                                <td>
                                    <button
                                        className={styles.updateBtn}
                                        onClick={() => {
                                            updateData(element);
                                            let tempEditArray = [...editArray];
                                            tempEditArray[index] = false;
                                            setEditArray(tempEditArray);
                                        }}
                                    >
                                        update
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
                </table>
            </Loader>
        </div>
    );
}
