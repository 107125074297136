import React from 'react'
import styles from './Header.module.css'


export default function Header() {
  
  return (
    <div className={styles.main}>
        <div className={styles.leftDiv}>
          <div className={styles.headerButtons} onClick={()=>{
              window.location.href='/Events'
          }}>Events</div>
          <div className={styles.headerButtons} onClick={()=>{
              window.location.href='/Gallery'
          }}>Gallery</div>
          <div className={styles.headerButtons} onClick={()=>{
              window.location.href='/Blogs'
          }}>Blogs</div>
        </div>
        <div className={styles.rightDiv}>
          <button className={styles.logoutBtn} onClick={()=>{
            window.localStorage.removeItem("isLoggedIn");
            window.location.reload();
          }}>Logout</button>
        </div>
    </div>
  )
}
