import Header from "./components/Header";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Gallery from "./components/Gallery";
import Events from "./components/Events";
import Blogs from "./components/Blogs";
import SignIn from "./components/SignIn";

function App() {

  return (
    <div>
      {window.localStorage.getItem("isLoggedIn")?(
        <BrowserRouter>
          <Header/>
          <Routes>
            <Route exact path="/" element={<Gallery />} />
            <Route exact path="/Gallery" element={<Gallery />} />
            <Route exact path="/Blogs" element={<Blogs />} />
            <Route exact path="/Events" element={<Events />} />
          </Routes>
        </BrowserRouter>
      ):(
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<SignIn />} />
            <Route exact path="/Gallery" element={<SignIn />} />
            <Route exact path="/Blogs" element={<SignIn />} />
            <Route exact path="/Events" element={<SignIn />} />
          </Routes>
        </BrowserRouter>
      )}
      
    </div>
  );
}

export default App;
