import React from "react";
import styles from "./Events.module.css";
import { useState, useEffect } from "react";
import axios from "axios";
const Buffer = require("buffer/").Buffer;
var Loader = require("react-loader");
const moment = require("moment");

export default function Events() {
    const server = process.env.REACT_APP_SERVER;

    const [addBox, setAddBox] = useState(false);
    const [eventsData, setEventsData] = useState([]);
    const [editArray, setEditArray] = useState([]);
    const [loaded, setLoaded] = useState(true);
    const [todayDate, setTodayDate] = useState();

    let newWidth = 400;
    
    function compressImage(image) {
        return new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.readAsDataURL(image);
      
          reader.onload = (event) => {
            let image_url = event.target.result;
      
            let image = document.createElement('img');
      
            image.src = image_url;
      
            image.onload = (e) => {
              let canvas = document.createElement('canvas');
              let aspectRatio = (newWidth) / (e.target.width);
              canvas.width = newWidth;
              canvas.height = e.target.height * aspectRatio;
      
              const context = canvas.getContext('2d');
              context.drawImage(image, 0, 0, canvas.width, canvas.height);
      
              let newImageUrl = context.canvas.toDataURL('image/jpeg', 100); // 70% of the original quality
      
              resolve(newImageUrl);
            };
          };
      
          reader.onerror = (error) => {
            reject(error);
          };
        });
      }

    function deleteData(element) {
        setLoaded(false);
        axios
            .post(server + "/deleteEventsData", element)
            .then((response) => {
                alert("Delete Successful");
                setLoaded(true);
                window.location.reload();
            })
            .catch((error) => {
                alert(
                    "Server error. Please try again or contact administrator"
                );
                console.log(error);
                setLoaded(true);
            });
    }

    function updateData(element) {
        setLoaded(false);

        let newData = {
            en: {
                title: element.en.title,
                description: element.en.description,
            },
            es: {
                title: element.es.title,
                description: element.es.description,
            },
            date: element.date,
            link: element.link,
        };

        newData._id = element._id;

        axios
            .post(server + "/updateEventsData", newData)
            .then((response) => {
                alert("Update Successful");
                setLoaded(true);
                window.location.reload();
            })
            .catch((error) => {
                alert(
                    "Server error. Please try again or contact administrator"
                );
                console.log(error);
                setLoaded(true);
            });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Display the loader
        setLoaded(false);

        const imageInput = document.getElementById("image-input");
        const images = Array.from(imageInput.files);

        const brochureENInput = document.getElementById("brochureEN-input");
        const brochuresEN = Array.from(brochureENInput.files);

        const brochureESInput = document.getElementById("brochureES-input");
        const brochuresES = Array.from(brochureESInput.files);

        const imageBase64Array = await Promise.all(
            images.map((image) => {
              return new Promise((resolve, reject) => {
                compressImage(image)
                  .then((compressedImage) => {
                    resolve(compressedImage);
                  })
                  .catch(reject);
              });
            })
          );

          console.log(imageBase64Array)

        const brochureENBase64Array = await Promise.all(
            brochuresEN.map((brochure) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(brochure);
                });
            })
        );

        const brochureESBase64Array = await Promise.all(
            brochuresES.map((brochure) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(brochure);
                });
            })
        );

        const formData = new FormData(event.target);
        imageBase64Array.forEach((base64, index) => {
            formData.append(`imagesBase64[${index}]`, base64);
        });

        brochureENBase64Array.forEach((base64, index) => {
            formData.append(`brochureENBase64[${index}]`, base64);
        });

        brochureESBase64Array.forEach((base64, index) => {
            formData.append(`brochureESBase64[${index}]`, base64);
        });

        console.log(formData);

        // Perform the form submission using Fetch API or any other library (e.g., Axios)
        fetch(server + "/saveEventsData", {
            method: "POST",
            body: formData,
        })
            .then((response) => {
                alert("New data added");
                setLoaded(true);
                window.location.reload();
            })
            .catch((error) => {
                alert(
                    "Server error. Please try again or contact administrator"
                );
                console.error(error);
                setLoaded(true);
            });
    };

    useEffect(() => {
        setLoaded(false);
        var currDate = new Date();
        var dd = String(currDate.getDate()).padStart(2, "0");
        var mm = String(currDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = currDate.getFullYear();

        currDate = yyyy + "-" + mm + "-" + dd;

        setTodayDate(currDate);

        axios
            .get(server + "/getEventsData")
            .then((response) => {
                const data = response.data;
                console.log(data);
                setEventsData(data);

                let tempEditArray = [];

                for (let i = 0; i < data.length; i++) {
                    tempEditArray.push(false);
                }

                setEditArray(tempEditArray);
                setLoaded(true);
            })
            .catch((error) => {
                alert(
                    "Server error. Please try again or contact administrator"
                );
                console.log(error);
                setLoaded(true);
            });
    }, []);

    return (
        <div>
            <div className={styles.header}>
                {addBox ? (
                    <div
                        className={styles.addButton}
                        onClick={() => {
                            setAddBox(!addBox);
                        }}
                    >
                        Close
                    </div>
                ) : (
                    <div
                        className={styles.addButton}
                        onClick={() => {
                            setAddBox(!addBox);
                        }}
                    >
                        New
                    </div>
                )}
            </div>

            <Loader loaded={loaded}>
                {addBox ? (
                    <form
                        enctype="multipart/form-data"
                        method="POST"
                        onSubmit={handleSubmit}
                    >
                        <div className={styles.addBox}>
                            <div className={styles.addSubBox}>
                                <div>Add poster: </div>
                                <input
                                    id="image-input"
                                    accept="image/*"
                                    type="file"
                                    name="posters"
                                    max="20971520"
                                    multiple
                                />
                            </div>
                            <div className={styles.addSubBox}>
                                <div>Brochure (EN) (PDF): </div>
                                <input
                                    id="brochureEN-input"
                                    type="file"
                                    max="20971520"
                                    name="brochureEN"
                                    multiple
                                />
                            </div>
                            <div className={styles.addSubBox}>
                                <div>Brochure (ES) (PDF): </div>
                                <input
                                    id="brochureES-input"
                                    type="file"
                                    max="20971520"
                                    name="brochureES"
                                    multiple
                                />
                            </div>
                            <div className={styles.addSubBox}>
                                <div>Add Event Date: </div>
                                <input
                                    type="date"
                                    name="date"
                                    defaultValue={todayDate}
                                />
                            </div>
                            <div className={styles.addSubBox}>
                                <div>Add Title (EN): </div>
                                <input type="text" name="titleEN" />
                            </div>
                            <div className={styles.addSubBox}>
                                <div>Add Description (EN): </div>
                                <textarea name="descriptionEN" />
                            </div>
                            <div className={styles.addSubBox}>
                                <div>Add Title (ES): </div>
                                <input type="text" name="titleES" />
                            </div>
                            <div className={styles.addSubBox}>
                                <div>Add Description (ES): </div>
                                <textarea name="descriptionES" />
                            </div>
                            <div className={styles.addSubBox}>
                                <div>Link: </div>
                                <input name="link" type="text" />
                            </div>

                            <input
                                type="submit"
                                value="Add"
                                className={styles.addButton}
                            />
                        </div>
                    </form>
                ) : null}

                <table>
                    <tr>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Title (ES)</th>
                        <th>Description (ES)</th>
                        <th>Date (yyyy-mm-dd)</th>
                        <th>Brochure (EN)</th>
                        <th>Brochure (ES)</th>
                        <th>Posters</th>
                        <th>Link</th>
                        <th>Delete</th>
                        <th>Edit</th>
                    </tr>
                    {eventsData.map((element, index) => {
                        return editArray[index] == false ? (
                            <tr>
                                <td>{element.en.title}</td>
                                <td>{element.en.description}</td>
                                <td>{element.es.title}</td>
                                <td>{element.es.description}</td>
                                <td>{element.date}</td>
                                <td>
                                    {element.brochureEN.map((pdf) => {
                                        return (
                                            <a href={pdf.url} target="_blank">
                                                PDF
                                            </a>
                                        );
                                    })}
                                </td>
                                <td className={styles.imageColumn}>
                                    {element.brochureES.map((pdf) => {
                                        return (
                                            <a href={pdf.url} target="_blank">
                                                PDF
                                            </a>
                                        );
                                    })}
                                </td>
                                <td className={styles.imageColumn}>
                                    {element.posters.map((image) => {
                                        return <img src={image.url} />;
                                    })}
                                </td>
                                <td>
                                    <a href={element.link} target="_blank">
                                        Click here
                                    </a>
                                </td>
                                <td>
                                    <button
                                        className={styles.deleteBtn}
                                        onClick={() => {
                                            deleteData(element);
                                        }}
                                    >
                                        Delete
                                    </button>
                                </td>
                                <td>
                                    <button
                                        className={styles.editBtn}
                                        onClick={() => {
                                            let tempEditArray = [...editArray];
                                            tempEditArray[index] = true;
                                            setEditArray(tempEditArray);
                                        }}
                                    >
                                        Edit
                                    </button>
                                </td>
                            </tr>
                        ) : (
                            <tr>
                                <td>
                                    <input
                                        placeholder="Title (EN)"
                                        defaultValue={element.en.title}
                                        onChange={(e) => {
                                            let tempEventsData = eventsData;
                                            tempEventsData[index].en.title =
                                                e.target.value;
                                            setEventsData(tempEventsData);
                                        }}
                                    />
                                </td>
                                <td>
                                    <textarea
                                        placeholder="Description (EN)"
                                        defaultValue={element.en.description}
                                        onChange={(e) => {
                                            let tempEventsData = eventsData;
                                            tempEventsData[
                                                index
                                            ].en.description = e.target.value;
                                            setEventsData(tempEventsData);
                                        }}
                                    />
                                </td>
                                <td>
                                    <input
                                        placeholder="Title (ES)"
                                        defaultValue={element.es.title}
                                        onChange={(e) => {
                                            let tempEventsData = eventsData;
                                            tempEventsData[index].es.title =
                                                e.target.value;
                                            setEventsData(tempEventsData);
                                        }}
                                    />
                                </td>
                                <td>
                                    <textarea
                                        placeholder="Description (ES)"
                                        defaultValue={element.es.description}
                                        onChange={(e) => {
                                            let tempEventsData = eventsData;
                                            tempEventsData[
                                                index
                                            ].es.description = e.target.value;
                                            setEventsData(tempEventsData);
                                        }}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="date"
                                        name="date"
                                        defaultValue={element.date}
                                        onChange={(e) => {
                                            let tempEventsData = eventsData;
                                            tempEventsData[index].date = moment(
                                                new Date(e.target.value)
                                            ).format("YYYY-MM-DD");
                                            setEventsData(tempEventsData);
                                        }}
                                    />
                                </td>
                                <td>
                                    {element.brochureEN.map((pdf) => {
                                        return (
                                            <a href={pdf.url} target="_blank">
                                                PDF
                                            </a>
                                        );
                                    })}
                                </td>
                                <td className={styles.imageColumn}>
                                    {element.brochureES.map((pdf) => {
                                        return (
                                            <a href={pdf.url} target="_blank">
                                                PDF
                                            </a>
                                        );
                                    })}
                                </td>
                                <td className={styles.imageColumn}>
                                    {element.posters.map((image) => {
                                        return <img src={image.url} />;
                                    })}
                                </td>
                                <td>
                                    <input
                                        placeholder="Link"
                                        defaultValue={element.link}
                                        onChange={(e) => {
                                            let tempEventsData = eventsData;
                                            tempEventsData[index].link =
                                                e.target.value;
                                            setEventsData(tempEventsData);
                                        }}
                                    />
                                </td>
                                <td>
                                    <button
                                        className={styles.deleteBtn}
                                        onClick={() => {
                                            deleteData(element);
                                        }}
                                    >
                                        Delete
                                    </button>
                                </td>
                                <td>
                                    <button
                                        className={styles.updateBtn}
                                        onClick={() => {
                                            updateData(element);
                                            let tempEditArray = [...editArray];
                                            tempEditArray[index] = false;
                                            setEditArray(tempEditArray);
                                        }}
                                    >
                                        update
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
                </table>
            </Loader>
        </div>
    );
}
