import React from "react";
import styles from "./Gallery.module.css";
import { useState, useEffect } from "react";
import axios from "axios";
const Buffer = require("buffer/").Buffer;
var Loader = require("react-loader");

export default function Gallery() {
    const server = process.env.REACT_APP_SERVER;

    const [addBox, setAddBox] = useState(false);
    const [galleryData, setGalleryData] = useState([]);
    const [editArray, setEditArray] = useState([]);
    const [loaded, setLoaded] = useState(true);

    let newWidth = 400;

    function compressImage(image) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsDataURL(image);

            reader.onload = (event) => {
                let image_url = event.target.result;

                let image = document.createElement("img");

                image.src = image_url;

                image.onload = (e) => {
                    let canvas = document.createElement("canvas");
                    let aspectRatio = newWidth / e.target.width;
                    canvas.width = newWidth;
                    canvas.height = e.target.height * aspectRatio;

                    const context = canvas.getContext("2d");
                    context.drawImage(image, 0, 0, canvas.width, canvas.height);

                    let newImageUrl = context.canvas.toDataURL(
                        "image/jpeg",
                        100
                    ); // 70% of the original quality

                    resolve(newImageUrl);
                };
            };

            reader.onerror = (error) => {
                reject(error);
            };
        });
    }

    function deleteData(element) {
        setLoaded(false);
        axios
            .post(server + "/deleteGalleryData", element)
            .then((response) => {
                alert("Delete Successful");
                setLoaded(true);
                window.location.reload();
            })
            .catch((error) => {
                alert(
                    "Server error. Please try again or contact administrator"
                );
                setLoaded(true);
            });
    }

    function updateData(element) {
        setLoaded(false);

        let newData = {
            en: {
                title: element.en.title,
                description: element.en.description,
            },
            es: {
                title: element.es.title,
                description: element.es.description,
            },
        };

        newData._id = element._id;

        axios
            .post(server + "/updateGalleryData", newData)
            .then((response) => {
                alert("Update Successful");
                setLoaded(true);
                window.location.reload();
            })
            .catch((error) => {
                alert(
                    "Server error. Please try again or contact administrator"
                );
                setLoaded(true);
            });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Display the loader
        setLoaded(false);

        const imageInput = document.getElementById("image-input");
        const images = Array.from(imageInput.files);

        // const imageBase64Array = await Promise.all(
        //     images.map((image) => {
        //       return new Promise((resolve, reject) => {
        //         compressImage(image)
        //           .then((compressedImage) => {
        //             resolve(compressedImage);
        //           })
        //           .catch(reject);
        //       });
        //     })
        //   );

        //   console.log(imageBase64Array)

        // const formData = new FormData(event.target);
        // imageBase64Array.forEach((base64, index) => {
        //     formData.append(`imagesBase64[${index}]`, base64);
        // });

        // Compressing and converting images to URLs

        const imageURLs = await Promise.all(
            images.map((image) => {
                return new Promise((resolve, reject) => {
                    compressImage(image)
                        .then((compressedImage) => {
                            axios
                                .post(
                                    "https://api.cloudinary.com/v1_1/daif8fct4/auto/upload",
                                    {
                                        file: compressedImage,
                                        upload_preset:
                                            process.env
                                                .REACT_APP_CLOUDINARY_UPLOAD_PRESET,
                                    }
                                )
                                .then((res) => {
                                    resolve(res.data.url);
                                })
                                .catch(reject);
                        })
                        .catch(reject);
                });
            })
        );

        console.log(imageURLs);

        const formData = new FormData(event.target);
        imageURLs.forEach((url, index) => {
            formData.append(`imageURLs[${index}]`, url);
        });

        // Perform the form submission using Fetch API or any other library (e.g., Axios)
        fetch(server + "/saveGalleryData", {
            method: "POST",
            body: formData,
        })
            .then((response) => {
                alert("New data added");
                setLoaded(true);
                window.location.reload();
            })
            .catch((error) => {
                alert(
                    "Server error. Please try again or contact administrator"
                );
                console.error(error);
                setLoaded(true);
            });
    };

    useEffect(() => {
        setLoaded(false);
        axios
            .get(server + "/getGalleryData")
            .then((response) => {
                const data = response.data;
                console.log(data);
                setGalleryData(data);

                let tempEditArray = [];

                for (let i = 0; i < data.length; i++) {
                    tempEditArray.push(false);
                }

                setEditArray(tempEditArray);
                setLoaded(true);
            })
            .catch((error) => {
                alert(
                    "Server error. Please try again or contact administrator"
                );
                console.log(error);
                setLoaded(true);
            });
    }, []);

    return (
        <div>
            <div className={styles.header}>
                {addBox ? (
                    <div
                        className={styles.addButton}
                        onClick={() => {
                            setAddBox(!addBox);
                        }}
                    >
                        Close
                    </div>
                ) : (
                    <div
                        className={styles.addButton}
                        onClick={() => {
                            setAddBox(!addBox);
                        }}
                    >
                        New
                    </div>
                )}
            </div>

            <Loader loaded={loaded}>
                {addBox ? (
                    <form
                        enctype="multipart/form-data"
                        method="POST"
                        onSubmit={handleSubmit}
                    >
                        <div className={styles.addBox}>
                            <div className={styles.addSubBox}>
                                <div>Add images: </div>
                                <input
                                    id="image-input"
                                    accept="image/*"
                                    type="file"
                                    name="images"
                                    max="20971520"
                                    multiple
                                />
                            </div>
                            <div className={styles.addSubBox}>
                                <div>Add Title (EN): </div>
                                <input type="text" name="titleEN" />
                            </div>
                            <div className={styles.addSubBox}>
                                <div>Add Description (EN): </div>
                                <textarea name="descriptionEN" />
                            </div>
                            <div className={styles.addSubBox}>
                                <div>Add Title (ES): </div>
                                <input type="text" name="titleES" />
                            </div>
                            <div className={styles.addSubBox}>
                                <div>Add Description (ES): </div>
                                <textarea name="descriptionES" />
                            </div>

                            <input
                                type="submit"
                                value="Add"
                                className={styles.addButton}
                            />
                        </div>
                    </form>
                ) : null}

                <table>
                    <tr>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Title (ES)</th>
                        <th>Description (ES)</th>
                        <th>Images</th>
                        <th>Delete</th>
                        <th>Edit</th>
                    </tr>
                    {galleryData.map((element, index) => {
                        return editArray[index] == false ? (
                            <tr>
                                <td>{element.en.title}</td>
                                <td>{element.en.description}</td>
                                <td>{element.es.title}</td>
                                <td>{element.es.description}</td>
                                <td className={styles.imageColumn}>
                                    {element.images.map((image) => {
                                        return <img src={image} />;
                                    })}
                                </td>
                                <td>
                                    <button
                                        className={styles.deleteBtn}
                                        onClick={() => {
                                            deleteData(element);
                                        }}
                                    >
                                        Delete
                                    </button>
                                </td>
                                <td>
                                    <button
                                        className={styles.editBtn}
                                        onClick={() => {
                                            let tempEditArray = [...editArray];
                                            tempEditArray[index] = true;
                                            setEditArray(tempEditArray);
                                        }}
                                    >
                                        Edit
                                    </button>
                                </td>
                            </tr>
                        ) : (
                            <tr>
                                <td>
                                    <input
                                        placeholder="Title (EN)"
                                        defaultValue={element.en.title}
                                        onChange={(e) => {
                                            let tempGalleryData = galleryData;
                                            tempGalleryData[index].en.title =
                                                e.target.value;
                                            setGalleryData(tempGalleryData);
                                        }}
                                    />
                                </td>
                                <td>
                                    <textarea
                                        placeholder="Description (EN)"
                                        defaultValue={element.en.description}
                                        onChange={(e) => {
                                            let tempGalleryData = galleryData;
                                            tempGalleryData[
                                                index
                                            ].en.description = e.target.value;
                                            setGalleryData(tempGalleryData);
                                        }}
                                    />
                                </td>
                                <td>
                                    <input
                                        placeholder="Title (ES)"
                                        defaultValue={element.es.title}
                                        onChange={(e) => {
                                            let tempGalleryData = galleryData;
                                            tempGalleryData[index].es.title =
                                                e.target.value;
                                            setGalleryData(tempGalleryData);
                                        }}
                                    />
                                </td>
                                <td>
                                    <textarea
                                        placeholder="Description (ES)"
                                        defaultValue={element.es.description}
                                        onChange={(e) => {
                                            let tempGalleryData = galleryData;
                                            tempGalleryData[
                                                index
                                            ].es.description = e.target.value;
                                            setGalleryData(tempGalleryData);
                                        }}
                                    />
                                </td>
                                <td className={styles.imageColumn}>
                                    {element.images.map((image) => {
                                        return <img src={image.url} />;
                                    })}
                                </td>
                                <td>
                                    <button
                                        className={styles.deleteBtn}
                                        onClick={() => {
                                            deleteData(element);
                                        }}
                                    >
                                        Delete
                                    </button>
                                </td>
                                <td>
                                    <button
                                        className={styles.updateBtn}
                                        onClick={() => {
                                            updateData(element);
                                            let tempEditArray = [...editArray];
                                            tempEditArray[index] = false;
                                            setEditArray(tempEditArray);
                                        }}
                                    >
                                        update
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
                </table>
            </Loader>
        </div>
    );
}
